import React, { useMemo } from 'react'
import { useParams } from 'react-router-dom'

import { useQuery } from '@tanstack/react-query'

import { formatDateToString } from '~/helpers'
import useQueryParams from '~/hooks/useQuery'
import Chart from '~/modules/retailMedia/components/Chart'
import WrapperChartAndCards from '~/modules/retailMedia/components/WrapperChartAndCards'
import { formatIndustryReportHistoryCampaign } from '~/modules/retailMedia/dtos/industry/campaign/reportHistory'
import {
  getCampaignHistoryReportService,
  getCampaignsReportHistoryKey
} from '~/modules/retailMedia/services/report'
import { useAppSelector } from '~/store/hooks'

import CardsCampaign from '../Cards'

type AdListFiltersProps = {
  adType?: CampaignAdType
}

const MetricsCampaignView = ({ adType }: AdListFiltersProps = {}) => {
  const { id } = useParams()

  const { startDate, endDate } = useAppSelector(state => state.datepickerHeader)

  const searchParams = useQueryParams()
  const product_sku = searchParams.get('product_sku') as RetailMediaAdsStatuses
  const ad_status = searchParams.get('ad_status') as RetailMediaAdsStatuses

  const queryParams = useMemo(
    () => ({
      start_date: formatDateToString(startDate, 'YYYY-MM-DD'),
      end_date: formatDateToString(endDate, 'YYYY-MM-DD'),
      campaign_id: id,
      product_sku: adType === 'product' ? product_sku : undefined,
      ad_status
    }),
    [startDate, endDate, id, adType, product_sku, ad_status]
  )

  const {
    data: chartData,
    isLoading: chartLoading,
    isPending,
    error: chartError
  } = useQuery({
    queryKey: [getCampaignsReportHistoryKey, id, ...Object.values(queryParams)],
    queryFn: async () => {
      const { data } = await getCampaignHistoryReportService(queryParams)

      return formatIndustryReportHistoryCampaign(data)
    }
  })

  return (
    <WrapperChartAndCards>
      <Chart
        isValidContent={!isPending}
        chartData={chartData}
        loading={chartLoading}
        error={chartError}
        initialMetrics={['clicks', 'impressions', 'conversions']}
        additionalMetricsKeys={['totalSpent', 'income']}
        selectQuantity={3}
      />

      <CardsCampaign />
    </WrapperChartAndCards>
  )
}

export default MetricsCampaignView
