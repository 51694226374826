import Loading from '~/components/Loading'
import CampaignPlacesTable from '~/modules/retailMedia/components/_tables/CampaignPlacesTable'
import Investment from '~/modules/retailMedia/components/CampaignForm/elements/Investment'
import { InfoView } from '~/modules/retailMedia/components/InfoView'
import { StatusBar } from '~/modules/retailMedia/components/StatusBar'
import StatusHistory from '~/modules/retailMedia/components/StatusHistory'

import DigitalSignageDetails from '../DigitalSignageDetails'

const ViewSponsoredBrandCampaign: React.FC<ViewCampaignProps> = ({
  data,
  loading
}) => {
  const statusBarOnTop = [
    'pending_review',
    'rejected',
    'approved',
    'awaiting_publication'
  ].includes(data.status)

  return (
    <>
      {statusBarOnTop && (
        <StatusBar
          status={data?.status}
          message={
            data?.status_history?.length > 0
              ? data?.status_history[0]?.note
              : ''
          }
        />
      )}

      <Loading status={loading}>Carregando dados da campanha...</Loading>

      <DigitalSignageDetails data={data} />

      <hr />

      <InfoView data={data} />

      <hr />

      <CampaignPlacesTable campaignId={data.id} />

      <hr />

      <Investment data={data} campaignId={data.id} initialData={data.raw} />

      <StatusBar
        status={data?.status}
        message={
          data?.status_history?.length > 0 ? data?.status_history[0]?.note : ''
        }
      />

      <hr />

      <StatusHistory data={data?.status_history} />
    </>
  )
}
export default ViewSponsoredBrandCampaign
