import { FiEdit2, FiPlus } from 'react-icons/fi'

import { t } from 'i18next'

import { Button } from '~/components/Buttons'
import { Badge } from '~/components/UI/Badge'
import { history } from '~/helpers'
import useQueryParams from '~/hooks/useQuery'

const AdvertiserTags: React.FC<AdvertiserTagsProps> = ({ data }) => {
  const tags = data?.advertiser_tags?.sort((a, b) =>
    a.label.localeCompare(b.label)
  )

  /**
   * Handle edit
   */
  const queryParams = useQueryParams()

  const { advertiser_id: advertiserId } = data

  const handleEdit = () => {
    queryParams.set('edit_tags_aid', advertiserId)
    history.push({ search: queryParams.toString() })
  }

  return (
    <section className="advertiser-ad_items d-flex flex-wrap gap-1 align-items-center">
      {tags?.map(item => (
        <Badge key={item.id} text={item.label} className="m-0" />
      ))}
      <Button
        size="xsmall"
        text={t(tags?.length ? 'c:actions.edit' : 'c:actions.add')}
        customWidth="auto"
        onClick={handleEdit}
        template="primaryOutlined"
        iconLeft={tags?.length ? <FiEdit2 /> : <FiPlus />}
      />
    </section>
  )
}

export default AdvertiserTags
