import { useCallback, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'

import { Scope } from '@unform/core'
import { Form } from '@unform/web'

import { Button } from '~/components/Buttons'
import Loading from '~/components/Loading'
import { useAPPTranslation } from '~/i18n/useAPPTranslation'
import { getCampaign } from '~/modules/retailMedia/store/campaign/actions'
import { useAppSelector } from '~/store/hooks'

import { addBanner } from '../../store/industry/ad/actions'
import { BannerAddFormContent } from '../CampaignForm/elements/Banner/BannerAddFormContent'

import * as S from './styles'

interface Props {
  showEdit: boolean
  formRef: React.MutableRefObject<unknown>

  queryParams: GetCampaignProps
  handleClickOnSave?: () => void
  handleCancel?: () => void
  setShowEdit?: (value: boolean) => void
}

export const Banner = ({
  showEdit,
  formRef,
  queryParams,
  handleClickOnSave,
  handleCancel,
  setShowEdit
}: Props) => {
  const { t } = useAPPTranslation()
  const dispatch = useDispatch()

  // copy of origin form
  const [bannersToTable, setBannersToTable] = useState([])

  const {
    campaign: {
      item: { data },
      products: { selectecProducts }
    },
    industry: {
      campaign: {
        patch_product: { loading: loadingSave }
      }
    }
  } = useAppSelector(state => state.retailMedia)

  // Atualiza os dados em caso de sucesso
  const handleSuccess = useCallback(() => {
    dispatch(getCampaign({ ...queryParams, account_info: true }))
    setShowEdit(false)
  }, [dispatch, queryParams, setShowEdit])

  // filtra somente item novo
  const newAds = useMemo(
    () =>
      selectecProducts
        .filter(item => !item.item_disabled)
        .map(item => ({
          settings: {
            ...item.settings,
            sku: `${item.product_sku}`
          }
        })),
    [selectecProducts]
  )

  // Request para adicionar novos items no ProductsPanel
  const handleSubmitEdit = useCallback(() => {
    const body = {
      campaign_id: data.id,
      publisher_id: data.publisher.id,
      assets: bannersToTable.map(({ ad_size, media_url, alt_text }) => ({
        ad_size,
        media_url,
        alt_text
      }))
    }

    try {
      dispatch(addBanner({ body, onSuccess: handleSuccess }))
    } catch (error) {
      console.error(error)
    }
  }, [bannersToTable, data, dispatch, handleSuccess])

  return (
    <Form ref={formRef} onSubmit={handleSubmitEdit} className="px-3">
      <Scope path="banner">
        <BannerAddFormContent
          formRef={formRef}
          bannersToTable={bannersToTable}
          setBannersToTable={setBannersToTable}
          selectedSegmentation={data?.targetingType}
          initialData={{ publisher_id: data?.publisher.id }}
        />
      </Scope>

      <footer className="mt-3">
        <Loading status={loadingSave}>{t('rm:savingChanges')}</Loading>

        {showEdit && !loadingSave && (
          <S.WrapperButtons className="form-default__buttons form-default__buttons-pb0">
            <Button
              template="transparentDanger"
              text={t('rm:button.Cancel')}
              onClick={handleCancel}
              disabled={loadingSave}
            />

            <Button
              template="success"
              text={t('rm:campaign.create.ProductsPanel.add.button')}
              textLoading={t('rm:saving')}
              onClick={handleClickOnSave}
              disabled={!bannersToTable.length || loadingSave}
              loading={loadingSave}
            />
          </S.WrapperButtons>
        )}
      </footer>
    </Form>
  )
}
