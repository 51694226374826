import { FormHandles } from '@unform/core'

import { Banner } from './Banner'
import { Product } from './Product'

interface Props {
  adType: CampaignAdType
  showEdit: boolean
  queryParams?: GetCampaignReportRequestParams
  formRef?: FormHandles
  handleClickOnSave?: () => void
  handleClose?: () => void
}

export const AddAd = ({
  adType,
  showEdit,
  formRef,
  queryParams,
  handleClickOnSave,
  handleClose
}: Props) => {
  const allComponents = {
    product: Product,
    banner: Banner
  }

  // @ts-expect-error ainda não temos adição de outros tipos
  const Component = allComponents[adType]

  return (
    <div className="AddAdForm">
      <Component
        showEdit={showEdit}
        formRef={formRef}
        queryParams={queryParams}
        handleClickOnSave={handleClickOnSave}
        handleCancel={handleClose}
        setShowEdit={handleClose}
      />
    </div>
  )
}
