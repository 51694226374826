import { useMemo } from 'react'
import { toastr } from 'react-redux-toastr'

import { useMutation, useQueryClient } from '@tanstack/react-query'
import { t } from 'i18next'

import Loading from '~/components/Loading'
import { history } from '~/helpers'
import { useAlert } from '~/hooks/useAlert'
import { useAppInfo } from '~/hooks/useAppInfo'
import useQueryParams from '~/hooks/useQuery'
import { adTypesOptions } from '~/modules/retailMedia/constants/adTypesOptions'
import { useAdTypes } from '~/modules/retailMedia/hooks/useAdTypes'
import { patchLinkPublisherAdvertiserService } from '~/modules/retailMedia/services/advertiser-publisher'

import FormEditLink from '../../../components/FormEditLink'
import { useAdvertiserPublisherLinks } from '../../hooks/useAdvertiserPublisherLinks'

// import { Container } from './styles';

interface FormData {
  type: Type
  seller_id?: string
  brands?: string[]
}

interface Type {
  all: All[]
  allObject: AllObject
  filtered: string[]
}

interface AllObject {
  type_product: boolean
  type_banner: boolean
  type_sponsored_brand: boolean
}

interface All {
  name: string
  value: CampaignAdType
  checked: boolean
}

const EditAdvertiserLink: React.FC = () => {
  const { linkConfig } = useAppInfo()

  const {
    data: adTypes = [],
    error,
    isPending
  } = useAdTypes({ useRMID: false })

  const [AlertError] = useAlert(error)

  const params = useQueryParams()
  const advertiserId = params.get('rmid')

  const {
    data: advertiserLinkData,
    queryKey: queryKeyAdvertiserLink,
    isPending: isPendingAdvertiserLink,
    queryKey
  } = useAdvertiserPublisherLinks({
    useAdvertiserId: !!advertiserId
  })

  const advertiserLink = useMemo(() => {
    const advertiser = advertiserLinkData?.data?.advertisers?.[0]

    return advertiser?.advertiser_id === advertiserId ? advertiser : null
  }, [advertiserId, advertiserLinkData?.data?.advertisers])

  const errorLink = {
    response: {
      data: { message: t('rm:config.advertisers.panel.error.loading') }
    }
  }

  const [AlertErrorLink] = useAlert(
    !isPendingAdvertiserLink && advertiserLink === null ? errorLink : false
  )

  const initialData = useMemo(
    () => ({
      ...advertiserLink,
      type: adTypes?.reduce(
        (acc, item) => ({
          ...acc,
          [`type_${item}`]: advertiserLink?.ad_types?.includes(item)
        }),
        {}
      )
    }),
    [adTypes, advertiserLink]
  )

  const handleClosePanel = () => {
    const newParams = new URLSearchParams(params.toString())
    newParams.delete('rmid')
    history.push({ search: newParams.toString() })
  }

  /**
   * Handle update
   */

  const queryClient = useQueryClient()

  const patchAdvertiserAdTypesMutation = useMutation({
    mutationFn: patchLinkPublisherAdvertiserService,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey
      })

      queryClient.invalidateQueries({
        queryKey: queryKeyAdvertiserLink
      })

      toastr.success(
        t('common:messages.success.title'),
        t('common:messages.success.message')
      )

      handleClosePanel()
    },
    onError: () => {
      toastr.error(
        t('common:messages.errorOccurred.title'),
        t('common:messages.errorOccurred.message')
      )
    }
  })

  const handleUpdateSubmit = async (data: FormData) => {
    const body: AdvertiserPublisherLinkPatchRequestBody = {
      seller_id: data.seller_id,
      ad_types: data.type.all
        .filter(item => !!item.checked)
        .map(item => item.value),
      brands: data?.brands || []
    }

    await patchAdvertiserAdTypesMutation.mutateAsync({
      advertiserId,
      body
    })
  }

  const options = useMemo(
    () =>
      adTypesOptions.filter(item =>
        adTypes.includes(item.value)
      ) as CheckboxOptionProps[],
    [adTypes]
  )

  return (
    <section className="px-3">
      <p>
        {t('rm:config.advertisers.panel.description')}{' '}
        <strong>
          {advertiserLink?.advertiser_name ||
            t('rm:config.advertisers.panel.advertiser.name.default')}
        </strong>
      </p>

      <Loading status={isPending || isPendingAdvertiserLink}>
        {t('common:actions.loading')}...
      </Loading>

      <AlertError />

      <AlertErrorLink />

      {!isPending && advertiserLink && (
        <FormEditLink
          handleCancel={handleClosePanel}
          options={options}
          initialData={initialData}
          onSubmit={handleUpdateSubmit}
          fields={linkConfig}
        />
      )}
    </section>
  )
}

export default EditAdvertiserLink
