import { t } from 'i18next'

import { Filters } from '~/components/Filters'
import Loading from '~/components/Loading'
import Panel from '~/components/Panel'
import AdsTable from '~/modules/retailMedia/components/_tables/AdsTable'
import CampaignCategoryTable from '~/modules/retailMedia/components/_tables/CampaignCategoriesTable'
import CampaignKeyWordTable from '~/modules/retailMedia/components/_tables/CampaignKeyWordTable'
import CampaignPlacementsTable from '~/modules/retailMedia/components/_tables/CampaignPlacementsTable'
import MediaRejectedTable from '~/modules/retailMedia/components/_tables/MediaRejectedTable'
import ProductsTable from '~/modules/retailMedia/components/_tables/ProductsTable'
import AudiencePanel from '~/modules/retailMedia/components/AudiencePanel'
import DestinationUrls from '~/modules/retailMedia/components/BannersTable/DestinationUrls'
import Budget from '~/modules/retailMedia/components/Budget'
import { DailyCost } from '~/modules/retailMedia/components/DailyCost'
import DailyHistoryTable from '~/modules/retailMedia/components/DailyHistoryTable'
import { InfoView } from '~/modules/retailMedia/components/InfoView'
import { ReviewBar } from '~/modules/retailMedia/components/ReviewBar'
import { StatusBar } from '~/modules/retailMedia/components/StatusBar'
import StatusHistory from '~/modules/retailMedia/components/StatusHistory'
import { retailMediaAdsFiltersInsideCampaignBanner } from '~/modules/retailMedia/store/common/filters'

import MetricsCampaignView from '../Metrics'

const ViewBannerCampaign: React.FC<ViewCampaignProps> = ({ data, loading }) => {
  const hasProducts = !!data.products?.length
  const hasKeywords =
    data?.targetingType === 'keyword' || !!data.keywords?.length
  const hasCategories =
    data?.targetingType === 'category' || !!data.banners?.categories?.length
  const hasPlacements = !!data.settings?.has_placements
  const hasAudience = !!data.audienceId

  const isReviewOrRejected = ['pending_review', 'rejected'].includes(
    data.status
  )
  const showDailyCost = !data.settings?.cpm

  return (
    <>
      {showDailyCost && <DailyCost data={data} />}

      {/* {isReviewOrRejected && (
        <> */}
      <ReviewBar
        status={data?.status}
        message={
          data?.status_history?.length > 0 ? data?.status_history[0]?.note : ''
        }
      />

      <hr />
      {/* </>
      )} */}

      {!isReviewOrRejected && (
        <>
          <Filters {...retailMediaAdsFiltersInsideCampaignBanner} columns={4} />
          <MetricsCampaignView />
        </>
      )}

      <Loading status={loading}>{t('rm:campaigns.loading')}</Loading>

      <AdsTable campaignAdType={data.adType} campaignStatus={data.status} />

      {!isReviewOrRejected && (
        <>
          <hr />

          <DailyHistoryTable
            campaignType={data.adType}
            campaignStartDate={data.raw.start_at}
          />
        </>
      )}

      {hasProducts && (
        <>
          <hr />
          <Panel title={t('rm:relatedProducts')} isCollapsible noPaddingBody>
            <ProductsTable campaignId={data.id} />
          </Panel>
        </>
      )}

      {hasKeywords && (
        <>
          <hr />
          <CampaignKeyWordTable campaignId={data.id} />
        </>
      )}

      {hasCategories && (
        <>
          <hr />
          <CampaignCategoryTable
            campaignId={data.id}
            publisherId={data.publisher.id}
          />
        </>
      )}

      {hasAudience && <AudiencePanel audienceId={data.audienceId} />}

      {hasPlacements && (
        <>
          <hr />
          <CampaignPlacementsTable campaignId={data.id} />
        </>
      )}

      <hr />

      <Budget data={data} loading={loading} />

      <InfoView data={data} />

      {!isReviewOrRejected && (
        <StatusBar
          status={data?.status}
          message={
            data?.status_history?.length > 0
              ? data?.status_history[0]?.note
              : ''
          }
        />
      )}

      <hr />

      <DestinationUrls data={data} loading={loading} />

      <MediaRejectedTable />

      <StatusHistory data={data?.status_history} />
    </>
  )
}

export default ViewBannerCampaign
