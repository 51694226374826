import { FiEdit2 } from 'react-icons/fi'

import { Button } from '~/components/Buttons'
import { getUserId } from '~/helpers'
import { useAPPTranslation } from '~/i18n/useAPPTranslation'

import { useUserList } from '../../pages/List/hooks/useUsersList'

const EditPermission = ({ data }: { data: UserResponse }) => {
  const { setEditUser } = useUserList()
  const { t } = useAPPTranslation()

  return (
    <Button
      template="transparentPrimary"
      onClick={() => setEditUser(data)}
      size="xsmall"
      customWidth="auto"
      iconRight={getUserId() !== data.id ? <FiEdit2 /> : null}
      text={t(`users:${data.role}`)}
      disabled={getUserId() === data.id}
    />
  )
}

export default EditPermission
