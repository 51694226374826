import { useQuery } from '@tanstack/react-query'

import Loading from '~/components/Loading'
import Panel from '~/components/Panel'

import { getAudienceService } from '../../services/audience'
import AudienceCard from '../Audiences/Card'

type AudiencePanelProps = {
  audienceId: BrandedType<number, 'AudienceId'>
}

const AudiencePanel: React.FC<AudiencePanelProps> = ({ audienceId }) => {
  const { isPending, data } = useQuery({
    queryKey: ['get-audience', audienceId],
    queryFn: async () => {
      const { data } = await getAudienceService(audienceId)

      return data
    }
  })

  return (
    <Panel title="Audiência">
      <Loading status={isPending} />
      {data && (
        <section className="border border-1 border-neutral-high-dark rounded">
          <AudienceCard data={data} isSelectedPreview readOnly />
        </section>
      )}
    </Panel>
  )
}

export default AudiencePanel
