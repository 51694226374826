import styled, { css } from 'styled-components'

import { FormGroup } from '~/components/unform'

export const Group = styled(FormGroup)`
  ${({ theme }) => css`
    --borderColor: var(--inative);
    --borderStyle: solid;

    &[data-template='highlight'] {
      --borderColor: var(--neutralLowLightest);
      --borderStyle: dashed;
    }

    & + & {
      margin-top: ${theme.spacings.medium};
    }

    > input {
      width: 100%;
    }

    /* &:last-child:not(:first-child) { */
    &:not(:first-child) {
      padding-top: ${theme.spacings.xsmall};
      border-top: 1px var(--borderStyle) var(--borderColor);
    }
  `}
`

export const WrapperAlerts = styled.div`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacings.medium};

    .alertInfo + .alertInfo {
      margin-top: ${theme.spacings.xxxsmall};
    }
  `}
`
