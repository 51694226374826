/* eslint-disable react/display-name */

import { useEffect, useMemo } from 'react'

import { AxiosError } from 'axios'
import { t } from 'i18next'

import AlertInfo from '~/components/AlertInfo'
import ListPagination from '~/components/ListPagination'
import Loading from '~/components/Loading'
import Table from '~/components/Table'
import { isReadOnly } from '~/helpers'
import { useSidePanel } from '~/hooks/useSidePanel'
import { useAPPTranslation } from '~/i18n/useAPPTranslation'

import { useUserList } from '../../pages/List/hooks/useUsersList'
import FormEditPermission from './FormEditPermission'
import { schema } from './schema'

const UsersTable = () => {
  const { t } = useAPPTranslation()
  const {
    isPending,
    data,
    total,
    page,
    error,
    quantity,
    handlePagination,
    handleItemsPerPageChange,
    editUser,
    setEditUser
  } = useUserList()

  const errorMessage = useMemo(() => {
    const errorRaw = error as AxiosError<APIErrorRawResponse>

    if (errorRaw && typeof errorRaw === 'object' && errorRaw.response) {
      const { status } = errorRaw.response

      if (status === 403) {
        return t('c:messages.unauthorized.message')
      }
    }

    return t('users:userList.errorAlert')
  }, [error, t])

  /**
   * Handle edit role
   */
  const { addSidePanel, removeSidePanel } = useSidePanel()

  useEffect(() => {
    if (editUser && !isReadOnly()) {
      addSidePanel({
        id: `panel-edit-user`,
        title: `${t('users:editPermission')}`,
        type: 'content',
        content: (
          <FormEditPermission
            key={editUser?.id}
            data={editUser}
            onClose={() => setEditUser(null)}
          />
        )
      })
    }

    if (!editUser) {
      removeSidePanel({
        id: `panel-edit-user`
      })
    }
  }, [addSidePanel, editUser, removeSidePanel, setEditUser, t])

  return (
    <>
      {error && <AlertInfo text={errorMessage} template="danger" />}

      <Loading status={isPending}>{t('users:userLoading')}</Loading>

      {data && (
        <Table total={total} schema={schema} data={data} smallerRowPadding />
      )}

      <ListPagination
        total={total}
        label={total > 1 ? t('users:users') : t('users:user')}
        currentPage={page}
        itemsPerPage={quantity}
        menuPlacement="top"
        onClickPagination={handlePagination}
        onItemsPerPageChange={handleItemsPerPageChange}
      />
    </>
  )
}

export default UsersTable
