import React from 'react'
import { FiExternalLink } from 'react-icons/fi'
import { NavLink } from 'react-router-dom'

import { useQuery } from '@tanstack/react-query'

import AlertInfo from '~/components/AlertInfo'
import { useAPPTranslation } from '~/i18n/useAPPTranslation'
import { listAdvertisersService } from '~/modules/retailMedia/services/advertiser-publisher'

// import { Container } from './styles';

type AlertDuplicatedProps = {
  field: 'advertiser_name' | 'seller_id'
  value: string
}

const AlertDuplicated: React.FC<AlertDuplicatedProps> = ({ field, value }) => {
  const { t } = useAPPTranslation()
  const {
    isPending,
    data: response,
    error
  } = useQuery({
    enabled: !!value,
    queryKey: ['checkDuplicatedAdvertiser', field, value],
    queryFn: async () => {
      const payload = {
        [field]: value
      }

      const response = await listAdvertisersService(payload)

      return response.data
    }
  })

  const content = {
    advertiser_name: {
      text: (
        <>
          O <strong>nome de anunciante</strong>, ou parte dele, está sendo usado
          por outra conta. Verifique se o anunciante já está cadastrado
        </>
      )
    },
    seller_id: {
      text: (
        <>
          Esse <strong>seller id</strong> já foi usado em outra conta de
          anunciante. Verifique se o anunciante já está cadastrado.
        </>
      )
    }
  }

  return (
    !!response?.total && (
      <AlertInfo template="warning" className="mt-2">
        {content[field].text}{' '}
        <NavLink
          to={`/retail-media/config/advertisers?${field}=${value}`}
          target="_blank"
        >
          {' '}
          aqui.
          <FiExternalLink />
        </NavLink>
      </AlertInfo>
    )
  )
}

export default AlertDuplicated
