import { FiCalendar } from 'react-icons/fi'

import { t } from 'i18next'

import { formatDateToString } from '~/helpers'
import { isNewItem } from '~/helpers/isNewItem'

import * as S from './styles'

export const TagNewItem = ({
  created,
  showBadgeNew = true
}: {
  created: string
  showBadgeNew?: boolean
}) => {
  const newItem = isNewItem({ date: created, daysThreshold: 1 }) && showBadgeNew

  return (
    <S.Container>
      <FiCalendar /> {t('rm:tag.newItem')}{' '}
      {formatDateToString(created, 'DD/MM/YYYY')}
      {newItem && <span>{t('rm:New')}</span>}
    </S.Container>
  )
}
