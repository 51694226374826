import { useCallback, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'

import { Form } from '@unform/web'
import { t } from 'i18next'

import { Button } from '~/components/Buttons'
import Loading from '~/components/Loading'
import {
  FormGroup,
  InputMoney,
  Label,
  Radio,
  Textarea
} from '~/components/unform'
import { formatMoneyV2, unformatMoney } from '~/helpers'
import { useAlert } from '~/hooks/useAlert'
import { useAppInfo } from '~/hooks/useAppInfo'
import { useAppSelector } from '~/store/hooks'

import { createTransaction } from '../../store/grocery/wallet/actions'

export const FormCreateTransaction = ({
  parentInitialData,
  currentBalance,
  checkingAccountId,
  onClose,
  onSuccess
}: {
  onSuccess(data: { newBalance: string }): void
} & FormCreateTransactionProps) => {
  const { lengthCentsCurrency } = useAppInfo()
  const [amount, setAmount] = useState<number>(parentInitialData?.amount || 0)

  /**
   * Redux
   */
  const { loading, error } = useAppSelector(
    ({ retailMedia }) => retailMedia.grocery.wallet.createTransaction
  )

  const dispatch = useDispatch()

  const [AlertOnError] = useAlert(error)

  const balanceAfter = useMemo(() => {
    const sum = Number(currentBalance) + Number(amount)

    return formatMoneyV2({ value: sum })
  }, [amount, currentBalance])

  /**
   * Handle change amount
   */
  const handleChangeAmount = useCallback(
    (data: InputMoneyValuesOnChange) => {
      const { floatValue } = data

      const value = lengthCentsCurrency
        ? Number(floatValue) / 100
        : Number(floatValue)

      setAmount(value)
    },
    [lengthCentsCurrency]
  )

  /**
   * Handle submit success
   */

  const handleSubmitSucess = useCallback(() => {
    onSuccess({ newBalance: balanceAfter })
  }, [balanceAfter, onSuccess])

  /**
   * Handle submit
   */

  const valueIsValid = useMemo(() => Number(amount) > 0, [amount])

  const handleSubmit = useCallback(
    (data: FormCreateTransactionSubmitData) => {
      const {
        transaction_subtype,
        amount: { numAsString },
        note
      } = data

      const amount = lengthCentsCurrency
        ? Number(numAsString) / 100
        : Number(numAsString)

      if (!valueIsValid) return

      const body: CreateTransactionBodyRequest = {
        transaction_type: 'credit',
        transaction_subtype,
        amount: String(amount),
        note
      }

      dispatch(
        createTransaction({
          values: { balanceAfter: String(balanceAfter) },
          body,
          checkingAccountId,
          onSuccess: handleSubmitSucess
        })
      )
    },
    [
      balanceAfter,
      checkingAccountId,
      dispatch,
      handleSubmitSucess,
      lengthCentsCurrency,
      valueIsValid
    ]
  )

  /**
   * Formatando initial data
   */

  const formattedInitialData = useMemo(
    () => ({
      amount: amount,
      transaction_subtype: 'common'
    }),
    [amount]
  )

  return (
    <Form onSubmit={handleSubmit} initialData={formattedInitialData}>
      <FormGroup>
        <Label text={t('rm:rechargeValue')} />
        <InputMoney
          name="amount"
          helpText={`${t('rm:balanceAfterRecharge')}: ${balanceAfter}`}
          required
          showWarningOnRequired
          onChange={handleChangeAmount}
        />
      </FormGroup>

      <FormGroup>
        <Label text={t('rm:creditType')} />
        <Radio
          itemSize="small"
          options={[
            { label: t('rm:Agreement'), value: 'common' },
            { label: t('rm:bonus'), value: 'bonus' }
          ]}
          inline
          name="transaction_subtype"
        />
      </FormGroup>

      <FormGroup>
        <Label
          text={t('rm:Note')}
          helperText={t('rm:transaction.noteHelper')}
        />
        <Textarea name="note" />
      </FormGroup>

      <Loading status={loading}>{t('rm:addingCredits')}...</Loading>

      <AlertOnError />

      {!loading && (
        <FormGroup className="actionButtons">
          <Button
            text={t('common:actions.cancel')}
            template="transparent"
            onClick={onClose}
          />
          <Button
            text={t('common:actions.add')}
            type="submit"
            disabled={!valueIsValid}
          />
        </FormGroup>
      )}
    </Form>
  )
}
