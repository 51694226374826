import styled, { css } from 'styled-components'

import { device } from '~/styles/device'

type WrapperMetricsProps = {
  gridLayout?: 'compact' | 'full'
}

const wrapperMetricsModifiers = {
  full: () => css`
    /* .wrapperChart {
      margin-bottom: 1rem;
    } */
  `,

  compact: () => css`
    display: grid;
    gap: 1rem;
    height: 432px;
    max-height: 90vw;
    margin-bottom: 1rem;

    grid-template:
      'chart card'
      / auto calc(400px + 0.5rem);

    padding-bottom: 0.5rem;

    @media ${device.laptop_max} {
      max-height: none;

      grid-template:
        'chart'
        'card';
    }

    .wrapperChart {
      grid-area: chart;
      max-height: 432px;
      min-height: 420px;

      @media ${device.laptop_max} {
        max-height: none;
        min-height: none;
      }

      .chart {
        min-height: auto !important;
        height: 100%;

        canvas {
          height: auto !important;
        }
      }
    }

    .wrapperCards {
      grid-area: card;
      padding: 2rem 0;

      @media ${device.laptop_max} {
        padding: 0;
      }

      .hold-cards {
        display: grid;
        gap: 0.5rem;
        grid-template-columns: 200px 200px;

        @media ${device.laptop_max} {
          grid-template-columns: repeat(auto-fill, minmax(45%, auto));
        }

        .card {
          max-width: 200px;
          margin: 0;

          .card-body {
            padding: 1rem;

            .media {
              align-items: center;

              .media-body {
                .value {
                  font-size: 0.875rem;
                }
              }
            }
          }
        }
      }
    }
  `
}

export const WrapperMetrics = styled.div<WrapperMetricsProps>`
  ${({ theme, gridLayout }) => css`
    position: relative;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid ${theme.colors.neutral.high.medium};

    /* @media ${device.laptop_max} {
      padding-top: 2rem;
    } */

    @media ${device.laptop_max} {
      .hold-cards {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 1rem;

        > div {
          margin: 0 !important;

          .card-body {
            padding: 1rem;

            header {
              span {
                line-height: 1.125;
              }
            }
          }
        }
      }
    }

    ${gridLayout === 'compact' && wrapperMetricsModifiers.compact()}
    ${gridLayout === 'full' && wrapperMetricsModifiers.full()}
  `}
`

export const NavLayout = styled.nav`
  ${({ theme }) => css`
    position: absolute;
    top: 0.25rem;
    right: 0;

    display: flex;
    align-items: center;

    font-size: 0.75rem;
    color: ${theme.colors.dark};

    @media ${device.laptop_max} {
      width: 100%;
    }

    small {
      margin-right: 0.25rem;
      padding: 0.25rem 0.75rem;
      border-right: 1px solid ${theme.colors.neutral.high.dark};
    }
  `}
`
