import React, { useMemo } from 'react'
import { FiRepeat, FiX } from 'react-icons/fi'

import { t } from 'i18next'

import { Button } from '~/components/Buttons'

type ReactivateButtonProps = {
  isActive: boolean
  onClick(): void
}

const activeProps = {
  text: t('rm:button.Cancel'),
  template: 'danger',
  size: 'xsmall',
  customWidth: 'auto',
  iconRight: <FiX />
} as ButtonProps

const defaultProps = {
  text: t('rm:campaign.button.reactivate'),
  size: 'medium',
  customWidth: 'auto',
  template: 'success',
  iconLeft: <FiRepeat />
} as ButtonProps

const ReactivateButton = ({ isActive, ...rest }: ReactivateButtonProps) => {
  const props = useMemo(
    () => (isActive ? activeProps : defaultProps),
    [isActive]
  )

  return <Button {...props} {...rest} />
}

export default ReactivateButton
