import { useMemo, useRef, useState } from 'react'
import { FiCheck, FiChevronDown, FiChevronUp, FiPlus } from 'react-icons/fi'
import { toastr } from 'react-redux-toastr'

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import { AxiosResponse } from 'axios'

import AlertInfo from '~/components/AlertInfo'
import { Button } from '~/components/Buttons'
import InputText from '~/components/Form/Input'
import Loading from '~/components/Loading'
import { Input, Label } from '~/components/unform'
import { useAlert } from '~/hooks/useAlert'
import { useGetFetchQuery } from '~/hooks/useGetFetchQuery'
import useQueryParams from '~/hooks/useQuery'
import { useAPPTranslation } from '~/i18n/useAPPTranslation'
import { listAdvertisersServiceKey } from '~/modules/retailMedia/services/advertiser-publisher'
import {
  createPublisherAdvertiserTagService,
  deletePublisherAdvertiserTagService
} from '~/modules/retailMedia/services/publisher-advertiser-tag'
import { listPublisherTagService } from '~/modules/retailMedia/services/publisher-tag'
import { setQuery } from '~/store/modules/filters/actions'

import { useAdvertiserPublisherLinks } from '../../hooks/useAdvertiserPublisherLinks'

// import { Container } from './styles';

const EditAdvertiserTags: React.FC = () => {
  const { t, tCapitalize } = useAPPTranslation()
  const params = useQueryParams()
  const advertiserId = params.get('edit_tags_aid') as BrandedType<
    string,
    'AdvertiserId'
  >

  /**
   * <Handle advertiser data>
   */
  const {
    data: advertiserLinkData,
    queryKey: queryKeyAdvertiserLink,
    isPending: isPendingAdvertiserLink,
    queryKey
  } = useAdvertiserPublisherLinks({
    useAdvertiserId: true
  })

  const advertiserLink = useMemo(() => {
    const advertiser = advertiserLinkData?.data?.advertisers?.[0]

    return advertiser?.advertiser_id === advertiserId ? advertiser : null
  }, [advertiserId, advertiserLinkData?.data?.advertisers])

  const errorLink = {
    response: {
      data: { message: t('rm:config.advertisers.panel.error.loading') }
    }
  }

  const [AlertErrorLink] = useAlert(
    !isPendingAdvertiserLink && advertiserLink === null ? errorLink : false
  )

  /**
   * </Handle advertiser data>
   */

  /**
   * <Handle publisher available tags>
   */
  const [query, setQuery] = useState('')
  const [createMode, setCreateMode] = useState(false)

  const {
    isPending,
    isFetching,
    data: response,
    error
  } = useQuery({
    queryKey: ['get-publisher-tags', query],
    queryFn: async () => {
      const params: ListPublisherTagRequest = { quantity: 100 }

      if (query) params.label = query

      const response = await listPublisherTagService(params)

      return response.data
    },
    placeholderData: previousData => previousData
  })

  const advertiserTags = useMemo(() => {
    const selectedTags = advertiserLink?.advertiser_tags?.map(
      item => item.tag_id
    )
    return response?.data
      ?.map(item => ({
        ...item,
        selected: selectedTags?.includes(item.id)
      }))
      .sort((a, b) => {
        if (a.selected && !b.selected) return 1
        if (!a.selected && b.selected) return -1
        return 0
      })
  }, [advertiserLink?.advertiser_tags, response?.data])

  /**
   * </Handle publisher available tags>
   */

  /**
   * <Handle search tag>
   */

  const handleSearchTag = (data: InputOnChangeData) => {
    setQuery(data.value)
  }

  /**
   * </Handle search tag>
   */

  /*
   * <Handle create tag>
   */
  const createFormRef = useRef<FormHandles>(null)
  const queryClient = useQueryClient()

  const { mutate, isPending: isPendingCreate } = useMutation({
    mutationFn: createPublisherAdvertiserTagService,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['get-publisher-tags']
      })
      queryClient.invalidateQueries({
        queryKey: [listAdvertisersServiceKey]
      })

      createFormRef?.current?.reset()
      setCreateMode(false)

      toastr.success(
        t('common:messages.success.title'),
        t('rm:config.advertisers.tags.panel.create.success')
      )
    },
    onError: () => {
      toastr.error(
        t('common:messages.errorOccurred.title'),
        t('common:messages.errorOccurred.message')
      )
    }
  })

  const handleCreateTag = async (
    data: Pick<CreatePublisherAdvertiserTagRequest, 'label' | 'description'>
  ) => {
    mutate({
      ...data,
      advertiser_id: advertiserId
    })
  }
  /*
   * </Handle create tag>
   */

  /*
   * <Handle remove tag>
   */

  const {
    mutate: mutateDelete,
    isPending: isPendingDelete,
    variables: deletedTagId
  } = useMutation({
    mutationFn: deletePublisherAdvertiserTagService,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['get-publisher-tags']
      })
      queryClient.invalidateQueries({
        queryKey: [listAdvertisersServiceKey]
      })

      toastr.success(
        t('common:messages.success.title'),
        t('rm:config.advertisers.tags.panel.delete.success')
      )
    },
    onError: () => {
      toastr.error(
        t('common:messages.errorOccurred.title'),
        t('common:messages.errorOccurred.message')
      )
    }
  })

  const handleDeleteTag = async (id: PublisherAdvertiserTagId) => {
    mutateDelete(id)
  }

  /*
   * </Handle remove tag>
   */

  return (
    <section className="px-3">
      <p>
        {t('rm:config.advertisers.tags.panel.description')}{' '}
        <strong>
          {advertiserLink?.advertiser_name ||
            t('rm:config.advertisers.panel.advertiser.name.default')}
        </strong>
      </p>

      <AlertErrorLink />

      <Loading status={isPendingAdvertiserLink}>
        {t('common:actions.loading')}...
      </Loading>

      {advertiserLink?.advertiser_tags?.length > 0 && (
        <>
          <ul className="list-group list-group-flush">
            {advertiserLink?.advertiser_tags?.map(item => (
              <div
                className="list-group-item d-flex justify-content-between"
                key={item.id}
              >
                <div className="card-body">
                  <h5 className="card-title">{item.label}</h5>
                  {item?.description && (
                    <p className="mb-0">{item.description}</p>
                  )}
                </div>
                <Button
                  size="small"
                  text="Remover"
                  template="danger"
                  onClick={() => handleDeleteTag(item.id)}
                  {...(deletedTagId === item.id && isPendingDelete
                    ? {
                        loading: true,
                        textLoading: 'Removendo...'
                      }
                    : {})}
                />
              </div>
            ))}
          </ul>

          <hr />
        </>
      )}

      <article>
        <fieldset className="mb-2">
          <Label
            text={t('rm:config.advertisers.tags.panel.add.description')}
            htmlFor="search_advertiser"
          />

          <InputText
            name="search_advertiser"
            type="search"
            onChange={handleSearchTag}
          />
        </fieldset>

        {!isPending && !response?.total && (
          <AlertInfo
            text={t('rm:config.advertisers.tags.panel.empty')}
            className="mb-2"
          />
        )}

        <section className="shadow-lg border rounded mb-3">
          <div className="py-1 px-2 border-bottom-0">
            <Button
              template="transparentPrimary"
              text="Adicionar tag nova"
              onClick={() => setCreateMode(prevState => !prevState)}
              size="small"
              iconLeft={
                createMode && !!response?.total ? (
                  <FiChevronUp />
                ) : (
                  <FiChevronDown />
                )
              }
            />
          </div>

          {(createMode || !response?.total) && (
            <div className="p-3 border-top">
              <Form
                ref={createFormRef}
                onSubmit={handleCreateTag}
                className="d-flex flex-wrap gap-2"
                initialData={{ label: query }}
              >
                <fieldset>
                  <Label text="Título" htmlFor="label" />
                  <Input name="label" placeholder="Nome da tag" />
                </fieldset>

                <fieldset className="flex-fill">
                  <Label text="Descrição" htmlFor="description" />
                  <Input name="description" placeholder="Descrição" />
                </fieldset>

                <footer className="w-100 d-flex gap-2">
                  {!!response?.total && (
                    <Button
                      template="deleteOutline"
                      text="Cancelar"
                      size="small"
                      onClick={() => setCreateMode(false)}
                    />
                  )}

                  <Button
                    type="submit"
                    template="success"
                    text="Criar e adicionar"
                    textLoading="Salvando..."
                    size="small"
                    loading={isPendingCreate}
                  />
                </footer>
              </Form>
            </div>
          )}
        </section>

        <Loading status={isPending || isFetching}>
          {t('common:actions.loading')}...
        </Loading>

        {advertiserTags?.length > 0 && (
          <>
            <ul className="list-group list-group-flush">
              {advertiserTags?.map(item => (
                <li
                  className="list-group-item d-flex justify-content-between"
                  key={item.id}
                >
                  <div>
                    <h5 className="card-title">{item.label}</h5>
                    {item?.description && (
                      <p className="mb-0">{item.description}</p>
                    )}
                  </div>

                  <Button
                    size="small"
                    onClick={() =>
                      handleCreateTag({
                        label: item.label,
                        description: item.description
                      })
                    }
                    text={
                      item.selected
                        ? tCapitalize('c:words.added')
                        : tCapitalize('c:actions.add')
                    }
                    template={item.selected ? 'primaryOutlined' : 'primary'}
                    disabled={item.selected}
                    iconLeft={
                      item.selected ? (
                        <FiCheck className="text-success" />
                      ) : (
                        <FiPlus />
                      )
                    }
                  />
                </li>
              ))}
            </ul>
          </>
        )}
      </article>
    </section>
  )
}

export default EditAdvertiserTags
